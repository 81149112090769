// Media Queryler
$mobil: 991px;
$tablet: 768px;
$desktop: 992px;


@mixin mobil {
  @media (max-width: $mobil) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin img-object-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin line-clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// KULLANIM
// @include mobil {
//   font-size: 1rem;
// }