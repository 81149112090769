@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont/iconfont.eot');
  src: url('../fonts/iconfont/iconfont.eot?#iefix') format('eot'),
    url('../fonts/iconfont/iconfont.woff2') format('woff2'),
    url('../fonts/iconfont/iconfont.woff') format('woff'),
    url('../fonts/iconfont/iconfont.ttf') format('truetype'),
    url('../fonts/iconfont/iconfont.svg#iconfont') format('svg');
}

.icon:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal !important;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  vertical-align: -.125em;
}

 .icon-arrow-left-short:before {
      content: "\E001";
    }

     .icon-arrow-left:before {
      content: "\E002";
    }

     .icon-arrow-right-circle-fill:before {
      content: "\E003";
    }

     .icon-arrow-right-short:before {
      content: "\E004";
    }

     .icon-arrow-right:before {
      content: "\E005";
    }

     .icon-chevron-compact-left:before {
      content: "\E006";
    }

     .icon-chevron-compact-right:before {
      content: "\E007";
    }

     .icon-chevron-left:before {
      content: "\E008";
    }

     .icon-chevron-right:before {
      content: "\E009";
    }

     .icon-envelope:before {
      content: "\E00A";
    }

     .icon-facebook:before {
      content: "\E00B";
    }

     .icon-geo-alt-fill:before {
      content: "\E00C";
    }

     .icon-globe2:before {
      content: "\E00D";
    }

     .icon-headset:before {
      content: "\E00E";
    }

     .icon-house:before {
      content: "\E00F";
    }

     .icon-instagram:before {
      content: "\E010";
    }

     .icon-linkedin:before {
      content: "\E011";
    }

     .icon-list-ul:before {
      content: "\E012";
    }

     .icon-pencil-square:before {
      content: "\E013";
    }

     .icon-pinterest:before {
      content: "\E014";
    }

     .icon-play-fill:before {
      content: "\E015";
    }

     .icon-telephone-fill:before {
      content: "\E016";
    }

     .icon-twitter-x:before {
      content: "\E017";
    }

     .icon-twitter:before {
      content: "\E018";
    }

     .icon-whatsapp:before {
      content: "\E019";
    }

     .icon-youtube:before {
      content: "\E01A";
    }

    