// Custom CSS Rules
:root {
  --transition-all: all ease-in-out .3s;

  --kirmizi: #d5101a;
  --turuncu: #e78c19;
  --lacivert: #203051;
  --bg-color: #fffcf8;
  --light-gradient: linear-gradient(-34deg, #ffffff 15%, #e3ebfc 50%, #ffffff 85%);
  --turuncu-gradient: linear-gradient(90deg, #e78c19 15%, #e78c19 50%, #d5101a 85%);
  --font-primary: 'Poppins', sans-serif; //300,700
  --font-secondary: 'DM Serif Display', serif;
}

// * {
//   //outline: 1px solid limegreen !important;
//   background: rgb(0 100 0 / 0.1) !important;
// }

html,
body {
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 16px;
  color: var(--lacivert);
  line-height: 1.8;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  background-color: var(--bg-color);
  position: relative;
}

a {
  transition: var(--transition-all);
  text-decoration: none;
}

.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--font-secondary);
  font-weight: 400;
  color: var(--lacivert);
}

.text-turuncu {color: var(--turuncu) !important;}
.text-lacivert {color: var(--lacivert) !important;}


.fw-300 {
  font-weight: 300 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.hidden-desktop {
  @include desktop {
    display: none;
  }
}

.hidden-mobil {
  @include mobil {
    display: none;
  }
}

section.section {
  padding-block: clamp(2.5rem, 7vw, 8rem);
  position: relative;
  isolation: isolate;
}

.bg-swirl {
  position: absolute;
  right: 0;
  top: 0;
  width: 40%;
  height: 70%;
  overflow: hidden;
  z-index: -1;
  display: block;

  img {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 140%;
  }
}

.bg-light-gradient {
  background-image: var(--light-gradient);
}

.bg-facebook {
  background-color: $facebook;
}
.bg-whatsapp {
  background-color: $whatsapp;
}
.bg-twitter-x {
  background-color: $twitter-x;
}
.bg-linkedin {
  background-color: $linkedin;
}
.bg-youtube {
  background-color: $youtube;
}
.bg-instagram {
  background-image: $instagram-gradient;
}

.text-whatsapp {
  color: $whatsapp;
}

input.form-control,
textarea.form-control {
  border-radius: 0;
  border: 0;
  background-color: #ffffffbf;
  border-right: 2px solid var(--turuncu);
  border-bottom: 2px solid var(--turuncu);
}

// Main Nav

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
}

.main-nav {
  @include desktop {
    backdrop-filter: blur(20px) saturate(3);
    -webkit-backdrop-filter: blur(20px) saturate(3);
  }
  @include mobil {
    background-color: #ffffffe3;
  }

  .btn-dil {
    background-color: var(--lacivert);
    color: #fff;
    border: 0;
    border-radius: 100px;

    @include mobil {
      width: 100%;
    }

    &:hover, &:active, &:focus {
      background-color: var(--turuncu);
    }

    &+.dropdown-menu {
      border: 0;
      border-radius: 0;
      box-shadow: 5px 5px 0 var(--turuncu);
     
      @include mobil {
        width: 100%;
      }
    }
  }

  .dil-link {
    img {
      width: 20px;
      height: 20px;
    }

    font-size: 13px;

    &:hover {
      background-color: var(--lacivert);
      color: #fff;
    }
  }
}

.nav-toggle-icon {
  span {
    display: inline-block;
    height: 2px;
    width: 30px;
    background-color: var(--lacivert);
    position: relative;

    &::after {
      content: '';
      width: 20px;
      height: 2px;
      background-color: var(--lacivert);
      position: absolute;
      right: 0;
      bottom: -5px;
    }
    &::before {
      content: '';
      width: 20px;
      height: 2px;
      background-color: var(--lacivert);
      position: absolute;
      right: 0;
      top: -5px;
    }
  }
}

// Hero Slider

.hero-slider {
  background-color: #f5f3ef;
  position: relative;
  isolation: isolate;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;

  .slider-bg-lettering {
    position: absolute;
    width: 60%;
    top: 20%;
    left: 50px;
    opacity: .6;
    z-index: 2;

    @include mobil {
      display: none;
    }
  }

  .blur-dot {
    position: absolute;
    border-radius: 999px;
    background: linear-gradient(214deg, #f83600 0%, #facc22 100%);
    aspect-ratio: 1 / 1;
    z-index: 1;

    &.blur-dot-1 {
      width: 300px;
      left: 0;
      top: 20%;
      filter: blur(3rem);
      left: -150px;
      animation : blurDotAnim1 5s infinite;
      animation-timing-function: ease-in-out;

      @include mobil {
        left: 0;
      }
    }
    &.blur-dot-2 {
      width: 600px;
      bottom: -50%;
      filter: blur(3rem);
      right: -50px;
      animation : blurDotAnim2 5s infinite;
      animation-timing-function: ease-in-out;

      @media (width > 1200px) {
        right: 100px;
      }

      @include mobil {
        left: 300px;
      }
    }
  }

  .swiper, .swiper-wrapper {
    z-index: 5;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .slide-content {
      text-align: center;

      @include desktop {
        max-width: 45%;
        text-align: start;
      }
    }
  }

  .slider-button {
    position: absolute;
    z-index: 7;
    top: calc(50% - 25px);
    background-color: #20305148;
    color: #fff;
    font-size: 40px;
    transition: var(--transition-all);
    opacity: .3;

    &:hover {
      background-color: #203051;
    }

    &.slider-button-prev {
      border-radius: 0 100px 100px 0;
    }
    &.slider-button-next {
      border-radius: 100px 0 0 100px;
      right: 0;
    }
  }

  &:hover .slider-button {
    opacity: 1;
  }
}

@keyframes blurDotAnim1 {
  from {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(50px);
  }
  to {
    transform: translateY(-50px);
  }
}

@keyframes blurDotAnim2 {
  from {
    transform: translateX(-100px);
  }
  50% {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}



.hero-slider-title {
  font-family: var(--font-secondary);
  font-size: clamp(2rem, 4vw, 4rem);
  color: var(--lacivert);
  text-shadow: 3px 3px 0 #fff;
  line-height: 1;
  margin-bottom: 15px;
}

.hero-slider-text {
  font-size: 13px;
}

.button {
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  box-shadow: 0 0 0 #fff;
  border: 0;
  transition: var(--transition-all);

  &:hover {
    box-shadow: 5px 5px 0 #fff;
  }

  &.primary-button {
    background-color: var(--lacivert);
    color: #fff;

    &:hover {
      background-color: var(--turuncu);
      color: var(--lacivert);
    }
  }
  &.secondary-button {
    background-color: var(--turuncu);
    color: var(--lacivert);

    &:hover {
      color: var(--lacivert);
      background-color: #fff;
      box-shadow: 5px 5px 0 var(--turuncu);
    }
  }
}

.hero-slider-img {
  position: absolute;
  width: 60%;
  aspect-ratio: 1 / 1;
  border-radius: 999px;
  isolation: isolate;

  @media (height < 801px) {
    top: -15%;
  }

  @include desktop {
    right: -10%;
  }
  @include mobil {
    position: relative;
    width: 90%;
    margin-inline: auto;
    margin-top: 160px;
    margin-bottom: -80px;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 999px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--turuncu);
    border-radius: 999px;
    left: -10px;
    bottom: -10px;
    display: block;

    @include mobil {
      left: 0;
    }
  }
}

.section-baslik {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 35px;

  @include mobil {
    flex-direction: column;
    gap: 15px;
  }

  .section-baslik-title {
    color: var(--lacivert);
    font-family: var(--font-secondary);
    font-size: 2.4rem;
    line-height: 1.2;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      background-color: var(--kirmizi);
      height: 3px;
      width: 12px;
      left: 138px;
      bottom: -10px;
      rotate: -45deg;
    }
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      background-image: var(--turuncu-gradient);
      height: 3px;
      width: 150px;
      left: 0;
      bottom: -5px;
    }
  }

  .section-baslik-link {
    font-size: 12px;
    letter-spacing: 2px;
    color: var(--lacivert);
    text-decoration: underline;
    line-height: 1.4;
    font-weight: 700;
    &:hover {
      color: var(--turuncu);
    }

  }
}

.hizmetlerimiz-grid {
  display: grid;
  gap: 1px;
  background-color: var(--turuncu);
  grid-template-columns: repeat(1, 1fr);

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }
  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }
  
  

}

.hizmetlerimiz-grid-card {
  display: flex;
  flex-direction: column;
  position: relative;
  aspect-ratio: 16 / 9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  isolation: isolate;
  justify-content: flex-end;

  padding: 25px;
  color: #fff;
  font-weight: 700;

  &::before {
    position: absolute;
    inset: 0;
    transition: var(--transition-all);
    display: block;
    content: "";
    background-color: var(--lacivert);
    opacity: .6;
    z-index: 1;
  }
  &::after {
    position: absolute;
    inset: 0;
    transition: var(--transition-all);
    display: block;
    content: "";
    border: 1px solid transparent;
    opacity: .6;
    z-index: 2;
  }

  .hizmetlerimiz-grid-card__title {
    position: relative;
    z-index: 3;
    margin-bottom: 0;
    transition: var(--transition-all);
  }

  &:hover {
    &::before {
      opacity: .9;
    }
    &::after {
      border-color: #fff;
      inset: 15px;
    }
    .hizmetlerimiz-grid-card__title {
      margin-bottom: 15px;
    }
  }
}

.hizmetler-2-grid {
  display: grid;
  gap: 4px;
  grid-auto-rows: 1fr;

  .hizmetler-2-grid-card {
    display: flex;
    flex-direction: column;
    padding: 70px 100px 70px 35px;
    position: relative;
    isolation: isolate;
    background-color: var(--lacivert);
    color: #fff;
    justify-content: flex-end;
    transition: var(--transition-all);
    border-bottom: 4px solid var(--turuncu);

    @include mobil {
      padding: 25px;
    }

    img {
      position: absolute;
      inset: 0;
      z-index: -1;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: .5;
      transition: var(--transition-all);
    }

    .hizmetler-2-grid-card__title {
      font-family: var(--font-secondary);
      font-size: 2rem;
      line-height: 1.3;
    }

    .hizmetler-2-grid-card__text {
      font-size: 13px;
      @include line-clamp(2);
    }

    .hizmetler-2-grid-card__button {
      display: grid;
      place-items: center;
      width: 70px;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      color: #fff;
      background-color: var(--turuncu);
      font-size: 40px;
      line-height: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 50px;
      opacity: 0;
      transition: var(--transition-all);
    }

    &:hover {
      padding-left: 40px;
      img {
        opacity: .3;
      }
      .hizmetler-2-grid-card__button {
        right: 35px;
        opacity: .8;
      }
    }
  }
}

.hizmet-noktalari-slider {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  position: relative;
}


.hizmet-nokta-card {
  display: flex;
  flex-direction: column;
  color: var(--lacivert);
  height: 100%;

  .hizmet-nokta-card__img {
    aspect-ratio: 16 / 10;
    @include img-object-fit;
    margin-bottom: 20px;
  }

  .hizmet-nokta-card-body {
    display: flex;
    height: 100%;
    flex-direction: column;

    .hizmet-nokta-card-title {
      font-size: 17px;
      font-weight: 700;
      margin-bottom: auto;
      line-height: 1.3;
      margin-bottom: 10px;
      transition: var(--transition-all);
    }

    .hizmet-nokta-card-sehir {
      font-size: 12px;
      color: #8e8e8e;
      text-transform: uppercase;
    }
  }

  &:hover {
    .hizmet-nokta-card-title  {
      color: var(--turuncu);
    }
  }
}

.slider-button-default {
  top: calc(50% - 15px);
  z-index: 2;
  color: var(--turuncu);
  font-size: 30px;
  line-height: 1;
  opacity: .8;
  position: absolute;

  &.slider-button-default-prev {
    scale: -1;
    left: 5px;
  }
  &.slider-button-default-next {
    right: 5px;
  }
}

.video-card {
  //padding: 25px;
  border: 1px solid var(--turuncu);
  border-radius: 10px;
  display: grid;
  place-items: center;
  isolation: isolate;
  position: relative;
  aspect-ratio: 16 / 12;

  .video-card-img {
    
    z-index: 1;
    overflow: hidden;
    position: absolute;
    inset: 25px;
    opacity: .7;
    transition: var(--transition-all);
    
    img {
      @include img-object-fit;
      transition: var(--transition-all);
    }
  }

  .icon {
    position: relative;
    z-index: 2;
    background-color: #ffffff8f;
    width: 40px;
    aspect-ratio: 1 / 1;
    border-radius: 100px;
    color: var(--turuncu);
    font-size: 30px;
    display: grid;
    place-items: center;
    transition: var(--transition-all);
  }

  &:hover {
    .video-card-img {
      opacity: 1;

      img {
        scale: 1.1;
      }
    }

    .icon {
      background-color: var(--turuncu);
      color: #fff;
    }
  }
}

.form-section {
  background-color: var(--lacivert);
  position: relative;
  isolation: isolate;
  background-image: url('../img/form-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    display: block;
    inset: 0;
    background-color: var(--lacivert);
    opacity: .8;
  }

  label {
    color: #fff;
    font-size: 12px;
  }
}

.blog-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 5px 5px 20px #00000026;
  color: #8e8e8e;
  position: relative;
  display: block;
  height: 100%;
  transition: var(--transition-all);

  &:hover {
    @include desktop {
      transform: translateY(-20px);
      background-color: #ffffffa1;
    }
  }

  

  article {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &:after {
    content: '“';
    position: absolute;
    z-index: 2;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    background-color: var(--turuncu);
    font-size: 3rem;
    border-radius: 4px;
    top: -1rem;
    left: 25px;
    line-height: 1;
    display: inline-block;
    display: grid;
    place-items: center;
    width: 35px;
    height: 35px;
  }
}

.blog-card-title {
  font-family: var(--font-secondary);
  color: var(--lacivert);
  font-size: 22px;

  @include desktop {
    @include ellipsis;
  }
}

.blog-card-text {
  font-size: 13px;
  @include line-clamp(3);
}

.devamini-oku-link {
  color: var(--turuncu);
  text-decoration: underline;
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: auto;
}

.iti {
  @include mobil {
    width: 100%;
  }
}

.iti__country-name {
  font-size: 13px;
}

.iti__country-list {
  right: 0;
}


// Footer
footer {
  ul {
    list-style: none;
  }
}
.footer-info {
  //background: #252525;
  padding: 45px 0;
  position: relative;

  @media (max-width: 767px) {
    text-align: center;
  }

  a, .textP {
    font-size: 13px;
    font-weight: 300;
    color: var(--lacivert);
    display: block;
  }

  a:hover {
      color: var(--turkuaz);
    }

  .logo img {
    height: 45px;
    object-fit: contain;
  }

  .title {
    font-size: 22px;
    color: var(--lacivert);
    font-weight: 300;
    margin-bottom: 0;
  }

  .footer-tel {
    color: var(--turuncu);
    font-size: 26px;
  }

  .footer-social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    @include mobil {
      justify-content: center;
    }

    a {
      font-size: 20px;

      &:hover {
        opacity: .6;
      }
    }

  }

  .footer-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 0;

    li {
      line-height: 1.5;
      a {
        font-size: 14px;
        &:hover {
          opacity: .6;
        }
      }
    }

    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  .end-item > * {
    text-align: right;

    @media (max-width: 767px) {
      text-align: center;
    }
  }
}

.zemedya {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 11px !important;
  height: 25px;
  padding-top: 20px;
  gap: 10px;
  border-top: 1px solid var(--turuncu);
  a {
    font-size: inherit;
  }
  a:hover {
    color: var(--koyu-mavi);
  }
}

.calisma-saatleri {
  font-size: 13px;
}

// ALT SAYFALAR

.bcmb {
  padding-top: 70px;
  border-bottom: 1px solid #e8e8e8;
  background-image: var(--light-gradient);

  .prev {
    @include mobil {
      display: none;
    }
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #9c9c9c;
    padding-block: 15px;

    a {
      color: var(--turuncu);
    }

    .history > * {
      display: inline-block;
      position: relative;

      &:not(:last-child) {
        &::after {
          content: "/";
          display: inline-block;
          margin-inline: 5px;
          color: #9c9c9c;
        }
      }
    }
  }
}

.hakkimizda-img {
  aspect-ratio: 2 / 1;
  @include img-object-fit;
  border-bottom: 4px solid var(--turuncu);
}

.kariyer-form {
  background-color: var(--lacivert);

  label {
    color: #fff;
  }
}

.hizmet-wrapper {
  position: relative;
  isolation: isolate;

  @include desktop {
    padding: 50px;
  }

  &::before {
    @include desktop {
      content: "";
      background-color: #e78a1941;
      display: block;
      border-radius: 20px;
      position: absolute;
      z-index: -1;
      width: 70%;
      left: 0;
      top: 0;
      bottom: 0;
      border-right: 6px solid #e78c19;
    } 

  }
}

.hizmet-hero {
  border-radius: 10px;
  position: sticky;
  top: 150px;
}

.hizmet-lead {
  font-size: 20px;
  font-weight: 700;
}

.merkez-wrapper .hizmet-nokta-card__img {
  aspect-ratio: 16 / 10;
  height: auto;
  transition: var(--transition-all);
}

.merkez-wrapper .hizmet-nokta-card__img:hover {
  opacity: .7;
}

.merkez-filtreleme {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;

  @include mobil {
    justify-content: center;
  }

  &>span {
    font-size: 12px;

    @include mobil {
      flex-basis: 100%;
      flex-shrink: 0;
      text-align: center;
    }
  }

  .filtre-button {
    background-color: #fff;
    border: 1px solid #d7dde2;
    border-radius: 60px;
    color: var(--lacivert);
    font-size: 13px;
    font-weight: 700;
    padding: 7px 15px;
    transition: var(--transition-all);

    &:hover, .active {
      background-color: #edf1f5;
    }

    @include mobil {
      font-size: 12px;
      padding: 4px 7px;
    }
  }
}

.hizmet-detay {
  img {
    aspect-ratio: 16 / 9;
    @include img-object-fit;
    border-radius: 20px;
    margin-bottom: 30px;
  }
}

.hizmet-baslik {
  border-bottom: 2px solid var(--turuncu);
  padding-bottom: 15px;
  margin-bottom: 1.5rem;
}

.hizmet-aside {
  position: sticky;
  top: 100px;
  background-color: #4c639113;
  padding: 15px;
  border-radius: 15px;

  @include desktop {
    padding: 40px;
  }
}

.hizmet-adres-baslik {
  font-weight: 700;
  font-size: 12px;
  .icon {
    display: inline-block;
    margin-inline-end: 6px;
  }
}

.hizmet-adres-text {
  margin-bottom: 20px;
  border-bottom: 1px solid #4c639152;
  padding-bottom: 15px;
}

.aside-social {
  display: flex;
  gap: 5px;

  a {
    display: grid;
    place-items: center;
    border-radius: 5px;
    width: 34px;
    height: 34px;
    line-height: 1;
    font-size: 20px;
    color: #fff;

    &:hover {
      opacity: .7;
    }
  }
}

.kroki {
  border: 1px solid var(--turuncu);
  iframe {
    display: block;
  }
}

.galeri-thumb {
  aspect-ratio: 4 / 3;
  @include img-object-fit;
}

.tanitim-section-hero {
  position: relative;
  aspect-ratio: 16 / 7;
  isolation: isolate;
  background-color: var(--lacivert);
  display: grid;
  place-items: center;
  color: #fff;
  padding: 20px;

  @include desktop {
    padding: 50px;
  }

  .tanitim-section-hero__img {
    position: absolute;
    opacity: .6;
    @include img-object-fit;
    z-index: -1;
  }

  .tanitim-section-hero__title {
    text-align: center;
    font-family: var(--font-secondary);
    margin: 0;

    font-size: clamp(2rem, 5vw, 7rem);
  }
}

.danismanlik-title {
  color: var(--turuncu);
  font-size: clamp(2rem, 4vw, 6rem);
  font-family: var(--font-secondary);
}

.danismanlik-grid {
  display: grid;
  gap: 20px;

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.danismanlik-grid-card {
  isolation: isolate;
  background-color: var(--lacivert);
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: flex-end;
  //overflow: hidden;
  position: relative;
  padding: 20px;

  @include mobil {
    aspect-ratio: 1 / 1;
  }
  @include desktop {
    padding: 35px;
  }
  
}

.danismanlik-grid-card__img {
  opacity: .6;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.danismanlik-grid-card__title {
  font-weight: 700;
}

.danismanlik-grid-card__text {
  font-size: 12px;
  line-height: 1.3;
}

@include desktop {
  .danismanlik-grid-card {
    &:nth-child(1) {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
    }
    &:nth-child(2) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    &:nth-child(3) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    &:nth-child(4) {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }
    &:nth-child(5) {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }

    &:not(:first-child) {
      aspect-ratio: 3 / 2;
    }
  }


}

.iletisim-telefon {
  a {
    color: var(--turuncu);
    font-size: 2rem;
    font-weight: 700;
  }
}

.iletisim-form {
  label {
    font-size: 12px;
    color: var(--turuncu);
  }

  .form-control {
    background-color: #20305115;
  }
}

.blog-detay-title {
  color: var(--lacivert);
  font-family: var(--font-secondary);
}

.blog-tarih {
  background-color: #e78a1930;
  font-weight: 700;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 20px;
  border-bottom: 3px solid var(--turuncu);
}