
/* NAVIGATION
================================*/
.navigation{
	@include flex();
	-ms-flex-align: initial;
	align-items: initial;
	width: 100%;
	min-height: $navigation-height;
	padding: 0;
	position: relative;
	line-height: initial;
	font-family: $navigation-font-family;
	box-sizing: border-box;
	background-color: $navigation-background-color;
	@include transition(background .3s);
	
	@media (min-width: $landscape-width){
		height: auto;
	}
	
	*,
	*:before,
	*:after{
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
		text-decoration: none;
	}
	
	// Fixed on top
	&.fixed-top{
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 19998;
	}
	
	// Sticky on top
	&.sticky-top{
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		will-change: opacity;
		z-index: 19998;
	}
	
	// Bootstrap container fix
	> .container{
		@include flex();
	}
}

/* MOBILE HEADER
================================*/
.navigation-header{
	width: 100%;
	@include flex();
	@include flexAlignCenterVertically();
	@include flexAlignBetween();
	
	@media (min-width: $landscape-width){
		display: none;
	}
}

/* OVERLAY PANEL
================================*/
.overlay-panel{
	width: 0;
	height: 0;
	position: fixed;
	left: 0;
	top: 0;
	opacity: 0;
	z-index: -999999;
	@include transition(opacity .8s);
	
	&.is-visible{
		width: 100%;
		height: 100%;
		opacity: 1;
		z-index: 19999;
	}
	
	&.is-invisible{
		opacity: 0;
	}
	
	@media (min-width: $landscape-width){
		&.is-visible{
			display: none !important;
		}
	}
}























